<template>
  <vDialog :visible.sync="visible" :visibleFooter="true">
    <div class="mb-4">
      <h3 class="noticeTitle mb-3 text-magenta text-center">Important Notice</h3>
      <div class="content">
        <p class="mb-2">
          Before transacting in leveraged financial derivative products, please understand the market risk carefully:
        </p>
        <p class="mb-2">
          Moneta is a regulated broker, who provides a range of professional online trading services to traders with all
          levels of experience and expertise. To avoid any conflict of interest, Moneta only provides financial
          derivatives quotes. The clients of Moneta are self-directed investors executing on an execution-only basis.
          This means that Moneta is not your advisor or acting in any fiduciary capacity for you. You are responsible
          for the trades you place, how you monitor the trades and any losses that result from this activity. As set out
          in the Client Agreement, Moneta shall not be liable for any losses or profits incurred as a result of your
          trading activities or undertaking any advice from a third party financial and capital management business.
        </p>
        <p class="mb-2">
          Moneta aim to provide a transparent trading environment to protect the fairness and objectivity of the
          financial markets, and we strive to ensure all clients clearly understand the risks associated with trading
          Forex and CFDs. Thank you for your continued support.
        </p>
        <p class="mb-2">
          Foreign Exchange and CFD trading investments are high risk and not suitable for everyone. You should carefully
          consider your investment objectives, level of experience and risk appetite before making a decision to trade
          with us.
        </p>
      </div>
    </div>
    <div class="mb-4">
      <h3 class="noticeTitle mb-3 text-magenta text-center">Pemberitahuan Penting</h3>
      <div class="content">
        <p class="mb-2">
          Sebelum bertransaksi pada produk derivatif keuangan dengan leverage, mohon pahami risiko pasar dengan cermat
          terlebih dahulu:
        </p>
        <p class="mb-2">
          Moneta adalah broker teregulasi, yang menyediakan berbagai layanan perdagangan online profesional kepada para
          trader dengan semua tingkat pengalaman dan keahlian. Untuk menghindari konflik kepentingan, maka Moneta hanya
          menyediakan kutipan derivatif keuangan. Klien Moneta adalah investor mandiri yang melakukan eksekusi berbasis
          dasar eksekusi saja. Ini berarti bahwa Moneta bukan penasihat Anda atau bertindak dalam kapasitas fidusia apa
          pun untuk Anda. Anda bertanggung jawab atas perdagangan yang Anda lakukan, bagaimana Anda memantau perdagangan
          anda dan kerugian apa pun yang terjadi dari aktivitas ini. Sebagaimana diatur dalam Perjanjian Klien, Moneta
          tidak bertanggung jawab atas kerugian atau keuntungan yang timbul sebagai hasil dari aktivitas perdagangan
          Anda atau dari saran apa pun dari manajemen keuangan bisnis dan kapital pihak ketiga.
        </p>
        <p class="mb-2">
          Moneta bertujuan untuk menyediakan lingkungan perdagangan yang transparan untuk melindungi keadilan dan
          objektivitas pasar keuangan, dan kami berusaha untuk memastikan semua klien memahami dengan jelas risiko yang
          terkait dengan perdagangan Forex dan CFD. Terima kasih atas dukungan Anda yang berkelanjutan.
        </p>
        <p class="mb-2">
          Investasi perdagangan Forex dan CFD berisiko tinggi dan tidak cocok untuk semua orang. Anda harus
          mempertimbangkan dengan cermat tujuan investasi, tingkat pengalaman, dan selera risiko Anda sebelum membuat
          keputusan untuk berdagang dengan kami.
        </p>
      </div>
    </div>
    <div class="mb-4">
      <h3 class="noticeTitle mb-3 text-magenta text-center">RISK WARNING:</h3>
      <div class="content">
        <p class="mb-2">
          Trading Forex and CFDs involves significant risk and can result in the losses greater than your invested
          capital. You should not invest more than you can afford to lose and should ensure that you fully understand
          the risks involved. Trading leveraged products may not be suitable for all investors. Before trading, please
          take into consideration your level of experience, investment objectives and seek independent financial advice
          if necessary. Please read our legal documents and ensure that you fully understand the risks before you make
          any trading decisions.
        </p>
      </div>
    </div>
    <div class="mb-4">
      <h3 class="noticeTitle mb-3 text-magenta text-center">PERINGATAN RISIKO:</h3>
      <div class="content">
        <p class="mb-2">
          Perdagangan Forex dan CFD melibatkan risiko yang signifikan dan dapat mengakibatkan kerugian yang lebih besar
          dari modal yang Anda investasikan. Anda tidak boleh berinvestasi lebih dari yang Anda mampu untuk kehilangan,
          dan harus memastikan bahwa Anda sepenuhnya memahami risiko yang ada. Perdagangan produk dengan leverage
          mungkin tidak cocok untuk semua investor. Sebelum berdagang, mohon pertimbangkan tingkat pengalaman Anda,
          tujuan investasi, dan mintalah nasihat keuangan independen jika diperlukan. Mohon baca dokumen legal kami
          sebelumnya dan pastikan bahwa Anda sepenuhnya memahami risikonya sebelum Anda membuat keputusan perdagangan
          apa pun.
        </p>
      </div>
    </div>
    <div class="mb-4">
      <div class="content">
        <p class="mb-2">
          Please note Moneta does not issue financial advice, recommendations or any opinions in relation to acquiring,
          holding or disposing of our products. Moneta is not a financial advisor and all services are provided on an
          execution only basis. We do not recommend clients to select or invest in any portfolios in the form of PAMM or
          MAM. Any trading and marketing activities of the PAMM or Money Manager are not associated with Moneta, and we
          have no liability as to the accuracy or completeness of any of the information provided by external providers,
          nor any losses that may arise from any information and trading activities supplied by the PAMM or Money
          Manager. Please be aware that investments in derivative products could be highly risky and carries the risk of
          losing substantially more than your investment. Please ensure you undertake your own thorough research before
          making a decision on any financial investments.
        </p>
        <p class="mb-2">
          Mohon diperhatikan bahwa Moneta tidak mengeluarkan nasihat keuangan, rekomendasi, atau pendapat apa pun
          terkait dengan pengambilan(posisi), penahanan(posisi), atau pelepasan(posisi) pada produk kami. Moneta bukan
          penasihat keuangan dan semua layanan disediakan berbasis dasar eksekusi saja. Kami tidak menyarankan klien
          untuk memilih atau berinvestasi dalam portofolio apa pun dalam bentuk PAMM atau MAM. Setiap aktivitas
          perdagangan dan pemasaran melalui PAMM atau yang dilakukan Money Manager tidak terkait dengan Moneta, dan kami
          tidak bertanggung jawab atas keakuratan atau kelengkapan informasi apa pun yang diberikan oleh pihak penyedia
          eksternal, juga atas kerugian apa pun yang mungkin timbul dari informasi dan aktivitas perdagangan yang
          dilakukan melalui PAMM atau dilakukan oleh Money Manager. Perlu diketahui bahwa investasi produk derivatif
          bisa sangat berisiko dan membawa risiko kerugian yang jauh lebih besar daripada investasi Anda. Harap pastikan
          Anda sendiri melakukan penelitian secara menyeluruh, sebelum membuat keputusan tentang investasi keuangan apa
          pun.
        </p>
      </div>
    </div>
  </vDialog>
</template>

<script>
import vDialog from './vDialog';

export default {
  name: 'RickDialog',
  props: {
    riskVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    riskVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:riskVisible', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/riskDialog.scss';
</style>
