<template>
  <!-- Reset Negative Balance dialog start -->
  <div class="active_dialog">
    <vDialog :visible.sync="visible" top="15%">
      <p>{{ $t('home.reset.creditTip') }}</p>
      <el-button class="btn_secondary" @click="visible = false">
        {{ $t('common.button.cancel') }}
      </el-button>
      <el-button class="btn_primary" @click="confirm" type="primary">
        {{ $t('common.button.confirm') }}
      </el-button>
    </vDialog>
  </div>
  <!-- Reset Negative Balance dialog end -->
</template>

<script>
import vDialog from './vDialog';

export default {
  name: 'ResetNegativeBalanceDialog',
  props: {
    resetConfirmVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    confirm() {
      this.$emit('actionResetNegativeBalance');
      this.visible = false;
    }
  },
  watch: {
    resetConfirmVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:resetConfirmVisible', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/active.scss';
</style>
