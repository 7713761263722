<template>
  <home :showLink="showLink">
    <div class="authentication"><AuthenticationNote /></div>
    <CashbackGuideBlock />
    <div>
      <div class="table_list_box"></div>
      <idPoa></idPoa>
      <h3>{{ $t('menu.liveAcc') }}</h3>
      <div class="table_item_box" v-if="$platform.getTradingPlatform(regulator).mt4">
        <div class="title clearfix">
          <div class="logo"><img src="@/assets/images/home_mt4_logo.png" alt /></div>
        </div>
        <!--MT4 Account-->
        <AccountTable
          :hideAccountTableData="mt4AccountTableData"
          :tradingViewComponent="tradingViewComponent"
          :showLink="showLink"
          :showDiffTable="showDiffTable"
          :showDiffButton="showDiffButton"
          :mtServer="4"
          :showResetNegativeColumn="showResetNegativeColumnMT4"
          @resetNegativeBalance="resetNegativeBalance"
          @showleverageInProcessPopUp="showleverageAlreadyInProcessPopUp"
          @leverageClick="leverageClick"
          @handleLock="handleLock"
          @changeTabele="addHidenTable"
        ></AccountTable>
        <div v-if="mt4HideAccountTableData.length > 0">
          <el-button class="bt_hide_table" @click="showMT4 = !showMT4"
            >{{ $t('common.button.hide') }} MT4 {{ $t('menu.accManagement')
            }}<i class="el-icon-arrow-up" :class="{ arrow_down: !showMT4 }"></i
          ></el-button>
          <el-collapse-transition>
            <div v-show="showMT4">
              <AccountTable
                :hideAccountTableData="mt4HideAccountTableData"
                :tradingViewComponent="tradingViewComponent"
                :showLink="showLink"
                :showDiffTable="showDiffTable"
                :showDiffButton="showDiffButton"
                :isHiddenTable="true"
                :mtServer="4"
                :showResetNegativeColumn="showResetNegativeColumnMT4"
                @resetNegativeBalance="resetNegativeBalance"
                @changeTabele="addTable"
              ></AccountTable>
            </div>
          </el-collapse-transition>
        </div>
      </div>

      <div class="table_item_box" v-if="$platform.getTradingPlatform(regulator).mt5">
        <div class="title clearfix">
          <div class="logo"><img src="@/assets/images/home_mt5_logo.png" alt /></div>
        </div>
        <!--MT5 Account-->
        <AccountTable
          :hideAccountTableData="mt5AccountTableData"
          :tradingViewComponent="tradingViewComponent"
          :showLink="showLink"
          :showDiffTable="showDiffTable"
          :showDiffButton="showDiffButton"
          :mtServer="5"
          :showResetNegativeColumn="showResetNegativeColumnMT5"
          @resetNegativeBalance="resetNegativeBalance"
          @showleverageInProcessPopUp="showleverageAlreadyInProcessPopUp"
          @leverageClick="leverageClick"
          @handleLock="handleLock"
          @changeTabele="addHidenTable"
        ></AccountTable>
        <div v-if="mt5HideAccountTableData.length > 0">
          <el-button class="bt_hide_table" @click="showMT5 = !showMT5"
            >{{ $t('common.button.hide') }} MT5 {{ $t('menu.accManagement')
            }}<i class="el-icon-arrow-up" :class="{ arrow_down: !showMT5 }"></i
          ></el-button>
          <el-collapse-transition>
            <div v-show="showMT5">
              <AccountTable
                :hideAccountTableData="mt5HideAccountTableData"
                :tradingViewComponent="tradingViewComponent"
                :showLink="showLink"
                :showDiffTable="showDiffTable"
                :showDiffButton="showDiffButton"
                :isHiddenTable="true"
                :mtServer="5"
                :showResetNegativeColumn="showResetNegativeColumnMT5"
                @resetNegativeBalance="resetNegativeBalance"
                @changeTabele="addTable"
              ></AccountTable>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </div>
    <template slot="components"
      ><!-- CHANGE LEVERAGE 弹框开始 -->
      <ChangeLeverage
        :changeLeverageVisible.sync="changeLeverageVisible"
        :leverageConfirmVisible.sync="leverageConfirmVisible"
        :leverageChangeSubmitSuccess.sync="leverageChangeSubmitSuccess"
        :leverageChangeSubmitFail.sync="leverageChangeSubmitFail"
        :leverageChangeSubmitReject.sync="leverageChangeSubmitReject"
        :leverageChangeAuditSuccess.sync="leverageChangeAuditSuccess"
        :leverageChangeSubmitTimeOut.sync="leverageChangeSubmitTimeOut"
        @resetTable="queryAllMetaTraderAccountDetails"
        method="live"
      ></ChangeLeverage>
      <!-- CHANGE LEVERAGE 弹框结束 -->

      <!-- CHANGE LEVERAGE confirm 弹框开始 -->
      <Common :homeVisible.sync="leverageConfirmVisible">
        <div class="dialog_body" v-if="leverageChangeSubmitSuccess">
          <i class="icon el-icon-circle-check text-green"></i>
          <p
            class="mt-3"
            v-html="
              leverageChangeAuditSuccess
                ? $t('home.leverage.changeLeverageAutoComplete')
                : $t('home.leverage.changeLeverageConfirm')
            "
          ></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitFail">
          <i class="icon el-icon-circle-close text-magenta"></i>
          <p class="mt-3" v-html="$t('home.leverage.changeLeverageFail')"></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitReject">
          <i class="icon el-icon-circle-close text-magenta"></i>
          <p class="mt-3" v-html="$t('home.leverage.changeLeverageReject')"></p>
        </div>
        <div class="dialog_body" v-if="leverageChangeSubmitTimeOut">
          <i class="icon el-icon-circle-close text-magenta"></i>
          <p
            class="mt-3"
            v-html="
              $t('home.leverage.changeLeverageDefault', {
                supportEmail: GLOBAL_CONTACT_EMAIL
              })
            "
          ></p>
        </div>
      </Common>
      <!-- CHANGE LEVERAGE confirm 弹框结束 -->

      <!-- CHANGE LEVERAGE already 弹框开始 -->
      <Common :homeVisible.sync="leverageAlreadyVisible">
        <i class="icon el-icon-circle-check text-green"></i>
        <p class="mt-3">{{ $t('home.leverage.alreadyApplied', { platform: $platform.info.fullName }) }}</p>
      </Common>
      <!-- CHANGE LEVERAGE already 弹框结束 -->

      <!-- 点击小锁弹框开始 -->
      <Passwork
        :passwordVisible.sync="passwordVisible"
        :forgotPwdVisible.sync="forgotPwdVisible"
        :number="number"
        :uuid="uuid"
        @forgotPwdClick="forgotPwdClick"
      ></Passwork>
      <!-- 点击小锁弹框结束 -->

      <!-- 点击 CHANGE PASSWORD  表单弹框开始 -->
      <ChangePassword
        :changePasswordVisible.sync="changePasswordVisible"
        :pwdConfirmVisible.sync="pwdConfirmVisible"
        :number="number"
        :isDemo="isDemo"
        @forgotPwdClick="forgotPwdClick"
      ></ChangePassword>
      <!-- 点击 CHANGE PASSWORD  表单弹框结束 -->

      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->
      <Common :homeVisible.sync="pwdConfirmVisible">
        <i class="icon el-icon-circle-check text-green"></i>
        <p v-html="$t('home.pw.confirm')"></p>
      </Common>
      <!-- CHANGE PASSWORD confirm 弹窗提示信息开始 -->

      <!-- FORGOT PASSWORD 开始 -->
      <Common :homeVisible.sync="forgotPwdVisible">
        <i class="icon el-icon-circle-check text-green"></i>
        <div class="mt-3" v-html="$t('home.pw.email')"></div>
      </Common>
      <!-- FORGOT PASSWORD 结束 -->

      <!-- active 弹框开始 -->
      <Active
        :uuid="uuid"
        :reActivateMetaTraderLogin="reActivateMetaTraderLogin"
        :activateVisible.sync="activateVisible"
        :activateConfirmVisible.sync="activateConfirmVisible"
        @resetTable="queryAllMetaTraderAccountDetails"
      ></Active>
      <!-- active 弹框结束 -->

      <!-- active Confirm 弹框开始 -->
      <Common :homeVisible.sync="activateConfirmVisible">
        <i class="icon el-icon-circle-check text-green"></i>
        <p v-html="$t('home.activate.confirm')"></p>
      </Common>
      <!-- active Confirm 弹框结束 -->

      <!-- risk Dialog 弹框开始 -->
      <RiskDialog :riskVisible.sync="riskVisible"> </RiskDialog>
      <!-- risk Dialog 弹框结束 -->

      <!-- Reset Negative Balance dialog start -->
      <ResetNegativeBalanceDialog
        :resetConfirmVisible.sync="showResetNegativeDialog"
        @actionResetNegativeBalance="actionResetNegativeBalance"
      ></ResetNegativeBalanceDialog>
      <!-- Reset Negative Balance dialog end -->

      <App :appVisible.sync="appVisible"></App>
    </template>
  </home>
</template>

<script>
import {
  apiQueryAllMetaTraderAccountDetails,
  apiQueryLeverageStatuses,
  apiReq_reset_mt4_password,
  apiRestAccountBalance
} from '@/resource';
import App from '@/components/home/App';
import Common from '@/components/home/Common';
import Active from '@/components/home/Active';
import Passwork from '@/components/home/Password';
import ChangePassword from '@/components/home/ChangePassword';
import ChangeLeverage from '@/components/home/ChangeLeverage';
import RiskDialog from '@/components/home/RiskDialog';
import AccountTable from '@/components/home/AccountTable';
import ResetNegativeBalanceDialog from '@/components/home/ResetNegativeBalanceDialog';
import CashbackGuideBlock from '@/components/home/CashbackGuideBlock';
import Home from '@/components/template/Home';
import idPoa from '@/components/idPoa/Index';
import idPoaMixin from '@/mixins/idPoa';
import changeLeverageMixins from '@/mixins/page/home/changeLeverage';
import greenIDMixin from '@/mixins/greenID';
import AuthenticationNote from '@/components/security/AuthenticationNote';

export default {
  name: 'HomePage',
  components: {
    App,
    Common,
    Active,
    Passwork,
    ChangePassword,
    ChangeLeverage,
    Home,
    idPoa,
    RiskDialog,
    AccountTable,
    ResetNegativeBalanceDialog,
    CashbackGuideBlock,
    AuthenticationNote,
  },
  mixins: [idPoaMixin, changeLeverageMixins, greenIDMixin],
  data() {
    return {
      uuid: this.$store.state.common.uuid,
      number: null,
      allMetaTraderAccounts: [],
      mt4AccountTableData: [],
      mt5AccountTableData: [],
      mt4HideAccountTableData: [],
      mt5HideAccountTableData: [],
      leverageStatus: {},
      leverageAlreadyVisible: false,
      passwordVisible: false,
      changePasswordVisible: false,
      // 小锁弹出框
      pwdConfirmVisible: false,
      forgotPwdVisible: false,
      // active 弹框
      activateVisible: false,
      activateConfirmVisible: false,
      riskVisible: false,
      reActivateMetaTraderLogin: null,
      isDemo: false,
      appVisible: false,
      showMT4: false,
      showMT5: false,
      showResetNegativeDialog: false,
      showResetNegativeColumnMT4: false,
      showResetNegativeColumnMT5: false,
      resetNegativeAccountNumber: 0
    };
  },
  mounted() {
    this.queryAllMetaTraderAccountDetails();
    this.checkLoginStatus();
  },
  computed: {
    tradingViewComponent() {
      return () => this.$platform.componentLoader(this.regulator, 'TradingViewColumn', true).component;
    },
    idPass() {
      console.log(this.$store.state.common.idPass);
      return this.$store.state.common.idPass;
    },
    showLink() {
      return this.allMetaTraderAccounts.length > 1;
    },
    showDiffTable() {
      return this.allMetaTraderAccounts.length === 1 && !this.idPass;
    },
    showDiffButton() {
      return this.allMetaTraderAccounts.length == 1 && !this.idPass && this.allMetaTraderAccounts[0].mt4_account != '-';
    },
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    },
    isLogin() {
      return this.$store.state.common.isLogin;
    }
  },
  methods: {
    queryAllMetaTraderAccountDetails() {
      apiQueryAllMetaTraderAccountDetails().then(resp => {
        if (Array.isArray(resp.data)) {
          this.allMetaTraderAccounts = resp.data;

          this.showResetNegativeColumnMT4 = resp.data.some(item => item.needRest && item.serverCategory === '4');
          this.showResetNegativeColumnMT5 = resp.data.some(item => item.needRest && item.serverCategory === '5');

          this.formatAccounts();
          this.queryLeverageStatus();
        }
      });
    },
    formatAccounts() {
      this.allMetaTraderAccounts.forEach(element => {
        if (element.status !== 1) {
          this.formatPendingAccount(element);
        }
        if (element.isArchive === 1 || element.isArchive === 2) {
          this.formatArchieveAccount(element);
        }
        this.setStatus(element);
      });
    },
    formatPendingAccount(account) {
      account.equity = '-';
      account.mt4_account = '-';
    },
    formatArchieveAccount(account) {
      account.equity = '-';
      account.currency = '-';
    },
    setStatus(account) {
      if (account.isArchive && (account.isArchive == 1 || account.isArchive == 2)) {
        account.status = 'Inactive';
        return;
      }
      if (account.status == 1) {
        account.status = 'Active';
        return;
      }
      if (account.status == 0) {
        account.status = 'Processing';
        return;
      }
      if (account.status == 2) {
        account.status = 'Rejected';
        return;
      }
    },
    queryLeverageStatus() {
      apiQueryLeverageStatuses({
        user_id: this.uuid
      }).then(resp => {
        this.leverageStatus = resp.data;
        this.updateLeverageStatus();
        this.seperateAccounts();
      });
    },
    updateLeverageStatus() {
      this.allMetaTraderAccounts.forEach(element => {
        if (element.mt4_account == '-' || element.mt4_account == null) {
          return;
        }
        if (this.leverageStatus[element.mt4_account.toString()]) {
          if (this.leverageStatus[element.mt4_account.toString()].status == 0) {
            element.leverageStatus = 0;
            return;
          }
        }
        element.leverageStatus = 1;
      });
    },
    seperateAccounts() {
      this.mt4AccountTableData = [];
      this.mt5AccountTableData = [];
      this.mt4HideAccountTableData = [];
      this.mt5HideAccountTableData = [];

      this.allMetaTraderAccounts.map(m => {
        m.serverCategory === '5'
          ? !m.isHidden
            ? this.mt5AccountTableData.push(m)
            : this.mt5HideAccountTableData.push(m)
          : !m.isHidden
          ? this.mt4AccountTableData.push(m)
          : this.mt4HideAccountTableData.push(m);
      });
    },
    showleverageAlreadyInProcessPopUp() {
      this.leverageAlreadyVisible = true;
    },
    // 点击小锁 第一个弹窗
    handleLock(index, row) {
      this.passwordVisible = true;
      this.number = row.mt4_account;
    },
    forgotPwdClick() {
      this.sendResetPasswordEmail().then(result => {
        let vm = this;
        if (result) {
          this.passwordVisible = false;
          this.forgotPwdVisible = true;
        } else {
          this.$message({
            message: vm.$t('home.pw.error'),
            type: 'error'
          });
        }
      });
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname;
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl,
        isDemo: this.isDemo
      }).then(resp => {
        if (resp.data.code === 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out');
            return Promise.resolve(true);
          } else {
            console.log('failed to sent out the reset password');
          }
        } else {
          console.log('Errors happened when applying for the reset password emails');
        }
        return Promise.resolve(false);
      });
    },
    openReactivateConfirmationPopUp(metaTraderLogin) {
      this.reActivateMetaTraderLogin = metaTraderLogin;
      this.activateVisible = true;
    },
    checkLoginStatus() {
      if (this.isLogin && this.countryCode === 6877 && this.regulator === 'vfsc') {
        this.$store.commit('common/setIsLogin', false);
        this.riskVisible = true;
      }
    },
    addHidenTable(data) {
      data.serverCategory === '5' ? this.mt5HideAccountTableData.push(data) : this.mt4HideAccountTableData.push(data);
    },
    addTable(data) {
      data.serverCategory === '5' ? this.mt5AccountTableData.push(data) : this.mt4AccountTableData.push(data);
    },
    resetNegativeBalance(row) {
      this.resetNegativeAccountNumber = row.mt4_account;
      row.credit > 0 ? (this.showResetNegativeDialog = true) : this.actionResetNegativeBalance();
    },
    actionResetNegativeBalance() {
      apiRestAccountBalance(this.resetNegativeAccountNumber).then(res => {
        res.data.data.code === 0
          ? this.resetNegativeBalanceSuccess()
          : this.$message({ message: this.$t(`responseMsg.${res.data.data.code}`), type: 'error' });
      });
    },
    resetNegativeBalanceSuccess() {
      this.resetNegativeAccountNumber = 0;
      this.$message({ message: this.$t('home.reset.success'), type: 'success' });
      this.queryAllMetaTraderAccountDetails();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
