export default {
  computed: {
    greenIDVerificationComponent() {
      return () => this.$platform.componentLoader(this.regulator, 'GreenIDVerification').component
    },
    greenIDStatus() {
      return this.$store.state.common.greenIDStatus
    },
    pendingGreenIDStatus() {
      return this.$store.state.common.pendingGreenIDStatus
    },
  },
}
