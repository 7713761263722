<template>
  <div id="cashbackGuideBlock" v-if="showCashBackContent">
    <h3>{{ $t('promotion.cashback.title.monetaProgram') }}</h3>
    <p class="subTitle">{{ $t('promotion.cashback.subTitle.lowerYourCost') }}</p>
    <div class="function_content">
      <div>
        <span class="title">
          <img src="@/assets/images/promotion/cashback/wallet.png" alt="wallet cion" />
          <span>{{ $t('promotion.cashback.subTitle.bonus') }} </span>
          <b>{{ $t('promotion.cashback.subTitle.wallet') }}</b>
        </span>
        <div class="content">
          <div class="left">
            <div class="sub_title">
              <p>{{ $t('promotion.cashback.subTitle.availableBonus') }}</p>
              <el-popover placement="top" width="200" trigger="hover">
                <img
                  slot="reference"
                  class="infoIcon"
                  src="@/assets/images/promotion/cashback/information.png"
                  alt="info icon"
                />
                <span class="popover_content">{{ $t('promotion.cashback.information.availableBonus1') }}</span>
              </el-popover>
            </div>
            <div class="amount">
              <p>
                <span>$</span>
                <span class="big" translate="no">{{ amountTemplate(bonus)[0] }}</span>
                <span>.</span>
                <span translate="no">{{ amountTemplate(bonus)[1] }}</span>
              </p>
            </div>
          </div>
          <div class="right">
            <el-button @click="$router.push('applyBonus')">
              {{ $t('promotion.cashback.button.applyForBonus') }}</el-button
            >
            <span class="howItWorks" @click="$router.push('cashbackRundown')">
              {{ $t('promotion.cashback.button.howItWorks') }}
            </span>
          </div>
        </div>
      </div>

      <img class="arrow" src="@/assets/images/promotion/cashback/three_green_arrow.png" alt="three green arrow" />

      <div>
        <span class="title">
          <img src="@/assets/images/promotion/cashback/cashback.png" alt="cashback icon" />
          <span>{{ $t('promotion.cashback.subTitle.cashback') }} </span>
          <b>{{ $t('promotion.cashback.subTitle.wallet') }}</b>
        </span>
        <div class="content">
          <div class="left">
            <div class="sub_title">
              <p>{{ $t('promotion.cashback.subTitle.totalBalance') }}</p>
              <el-popover placement="top" width="200" trigger="hover">
                <img
                  slot="reference"
                  class="infoIcon"
                  src="@/assets/images/promotion/cashback/information.png"
                  alt="info icon"
                />
                <span class="popover_content">{{ $t('promotion.cashback.information.total') }}</span>
              </el-popover>
            </div>
            <div class="amount">
              <p>
                <span>$</span>
                <span class="big" translate="no">{{ amountTemplate(totalCashback)[0] }}</span>
                <span>.</span>
                <span translate="no">{{ amountTemplate(totalCashback)[1] }}</span>
              </p>
            </div>
          </div>
          <div class="right">
            <el-button @click="$router.push('cashback')">{{
              $t('promotion.cashback.button.redeemCashback')
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiCampaignInfo } from '@/resource';
import splitThousands from '@/util/splitThousands';

export default {
  name: 'CashbackGuideBlock',
  data() {
    return {
      bonus: 0,
      totalCashback: 0
    };
  },
  mounted() {
    this.getCashbackInfo();
  },
  computed: {
    showCashBackContent() {
      return this.campaignID ? true : false;
    },
    campaignID() {
      let campaign = this.$store.state.promotion.eligibleCampaigns.find(c => c.campaignType === 'CASHBACK_2');
      return campaign ? campaign.id : null;
    }
  },
  watch: {
    campaignID() {
      this.getCashbackInfo();
    }
  },
  methods: {
    getCashbackInfo() {
      if (this.campaignID) {
        apiCampaignInfo(this.campaignID)
          .then(resp => {
            if (resp.data.code === 0) {
              this.bonus = resp.data.data.bonus;
              this.totalCashback = resp.data.data.totalCashback;
            }
          })
          .catch(error => {
            this.showError();
          });
      }
    },
    amountTemplate(amount) {
      return splitThousands(amount).split('.');
    },
    showError() {
      this.$message({
        message: this.$t('common.field.failed'),
        type: 'error',
        duration: 5000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/cashbackGuideBlock.scss';
</style>
