function splitThousands(num, fixed = 2) {
  let decimals = ''
  for (let index = 0; index < fixed; index++) {
    decimals = decimals += '0'
  }

  let regNumber = /^(\-)?\d+(\.\d+)?$/
  if (!regNumber.test(num)) {
    return `0.${decimals}`
  }

  let reg = /\B(?=(\d{3})+$)/g
  num = num.toString().split('.')

  num[0] = num[0].replace(reg, ',')
  num[1] = num[1] ? num[1].concat(decimals).substr(0, fixed) : decimals

  return num.join('.')
}
export default splitThousands
