<template>
  <vDialog :visible.sync="visible" :visibleFooter="false" :top="'0'" additionalClass="app-dialog">
    <div class="app">
      <div class="title">
        <div class="desc">{{ $t('home.app.title') }}</div>
        <i class="el-icon-close" @click="visible = false" data-testid="closeImg"></i>
      </div>

      <div class="appDesc">
        <p>1. {{ $t('home.app.desc01', { platform: $platform.info.fullName }) }}</p>
        <img src="@/assets/images/home/app01.png" alt="" />
      </div>
      <div class="appDesc">
        <p>2. {{ $t('home.app.desc02') }}</p>
        <img src="@/assets/images/home/app02.png" alt="" />
      </div>
      <div class="appDesc">
        <p>3. {{ $t('home.app.desc03') }}</p>
        <img src="@/assets/images/home/app03.png" alt="" />
      </div>
      <div class="appDesc">
        <p>4. {{ $t('home.app.desc04') }}</p>
      </div>
    </div>
  </vDialog>
</template>

<script>
import vDialog from './vDialog';

export default {
  name: 'App',
  props: {
    appVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    appVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:appVisible', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/app.scss';
</style>
