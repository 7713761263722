<template>
  <div class="table_box" :class="{ hidden_table_box: isHiddenTable }">
    <el-table stripe size="mini" border :data="hideAccountTableData">
      <el-table-column :label="$t('promotion.referAFriend.acc')" min-width="90">
        <template slot-scope="scope">
          <div>
            <el-button
              type="primary"
              class="text-uppercase"
              @click="hideClick(scope.$index, hideAccountTableData, scope.row)"
              :data-testid="mtServer === 5 ? 'hideMT5Account' : 'hideMT4Account'"
            >
              {{ isHiddenTable ? $t('common.button.unhide') : $t('common.button.hide') }}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mt4_account" :label="$t('common.column.accNum')" min-width="90"></el-table-column>
      <el-table-column
        show-overflow-tooltip
        class="text-transform-none"
        prop="server"
        :label="$t('common.column.server')"
        min-width="170"
      ></el-table-column>
      <el-table-column :label="$t('common.column.type')" min-width="90">
        <template slot-scope="scope">
          {{ $platform.accountTypeMaps(regulator)[scope.row.mt4_account_type] }}
        </template>
      </el-table-column>
      <el-table-column prop="currency" :label="$t('common.column.currency')" min-width="90"></el-table-column>
      <el-table-column :label="$t('common.column.equity')" min-width="90">
        <template slot-scope="scope">{{ scope.row.equity | formatNumber }}</template>
      </el-table-column>
      <el-table-column :label="$t('common.column.credits')" min-width="90">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'Active'">{{scope.row.credit | formatNumber}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.column.balance')" min-width="90">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'Active'">{{scope.row.balance | formatNumber}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.column.leverage')" min-width="90">
        <template slot-scope="scope">
          <div v-if="scope.row.status === 'Active'">
            <span class="w-45 text-end">{{ scope.row.leverage }} : 1</span>
            <div class="d-inline-block" v-if="!scope.row.isInBlackList">
              <a v-if="scope.row.leverageStatus === 1">
                <i
                  class="table-icon el-icon-caret-bottom text-clear-blue"
                  @click.stop.prevent="leverageClick(scope.$index, scope.row)"
                  :data-testid="mtServer === 5 ? 'leverageMt5' : 'leverageMt4'"
                  v-if="!isHiddenTable"
                ></i>
              </a>
              <a v-else>
                <img
                  src="@/assets/images/error_icon.png"
                  @click.stop.prevent="showleverageAlreadyInProcessPopUp()"
                  alt="img"
                />
              </a>
              <a v-if="scope.row.tag">
                <el-popover
                  class="tooltip"
                  popper-class="restore_popper"
                  placement="right-start"
                  width="240"
                  trigger="hover"
                >
                  <img class="popover_icon" src="@/assets/images/help.png" slot="reference" />
                  <div class="popover_content" v-html="$t('home.leverage.autoRestore')" />
                </el-popover>
              </a>
            </div>
          </div>
          <em v-else>-</em>
        </template>
      </el-table-column>
      <el-table-column v-if="!showDiffTable" :label="$t('common.column.status')" min-width="90">
        <template slot-scope="scope">
          <div :class="getStatusColor(scope.row.status)">{{ scope.row.status | accStatus }}</div>
        </template>
      </el-table-column>
      <el-table-column v-else :label="$t('common.column.status')" min-width="90">
        <div>
          <span class="w-45 text-end red">pending</span>
          <div class="d-inline-block">
            <a>
              <el-tooltip
                effect="dark"
                :content="$t('home.pendingTooltip', { number: mtServer })"
                placement="bottom"
                popper-class="homeTooltip"
              >
              </el-tooltip>
            </a>
          </div>
        </div>
      </el-table-column>
      <component :is="tradingViewComponent" :isHiddenTable="isHiddenTable" :mtServer="mtServer"></component>
      <el-table-column
        v-if="!showDiffTable && !showDiffButton"
        :label="mtServer === 5 ? $t('common.column.resetMT5PW') : $t('common.column.resetMT4PW')"
        min-width="90"
      >
        <template slot-scope="scope">
          <a
            v-if="scope.row.status === 'Active'"
            @click.stop.prevent="handleLock(scope.$index, scope.row)"
            :data-testid="mtServer === 5 ? 'resetMT5PW' : 'resetMT4PW'"
          >
            <img src="@/assets/images/lock_icon.png" v-if="!isHiddenTable" alt />
            <img src="@/assets/images/lock_icon-dark.png" v-else alt />
          </a>
          <em v-else>-</em>
        </template>
      </el-table-column>
      <el-table-column v-else-if="showDiffButton" :label="$t('common.button.depositFunds')" min-width="90">
        <el-button
          :class="{ 'bg-light-green': showIdentityProofAlert }"
          @click="$router.push('depositFunds')"
          data-testid="depositFunds"
        >
          {{ $t('common.button.depositFunds') }}
        </el-button>
      </el-table-column>

      <!-- ACM-20926 緊急暫時隱藏按鈕 -->
      <!-- <el-table-column :label="$t('home.reset.tableHeader')" min-width="90" v-if="showResetNegativeColumn">
        <template slot-scope="scope">
          <el-button
            type="danger"
            v-if="scope.row.needRest"
            @click="clickResetButton(scope.row)"
            data-testid="resetNegativeBalance"
          >
            {{ $t('home.reset.resetBtn') }}
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import idPoaMixin from '@/mixins/idPoa';
import { apiUpdateMetaTraderAccountHide } from '@/resource';

export default {
  mixins: [idPoaMixin],
  props: {
    hideAccountTableData: Array,
    tradingViewComponent: Function,
    showLink: Boolean,
    showDiffTable: Boolean,
    showDiffButton: Boolean,
    mtServer: Number,
    isHiddenTable: {
      type: Boolean,
      default: false
    },
    showResetNegativeColumn: Boolean
  },
  methods: {
    showleverageAlreadyInProcessPopUp() {
      this.$emit('showleverageInProcessPopUp');
    },
    handleLock(index, row) {
      this.$emit('handleLock', index, row);
    },
    getStatusColor(status) {
      if (this.isHiddenTable) return;

      switch (status) {
        case 'Active':
          return 'text-green';
        case 'Inactive':
          return 'text-ghost-gray';
        case 'Processing':
          return '';
        case 'Rejected':
          return 'text-magenta';
        default:
          return '';
      }
    },
    hideClick(index, rows, row) {
      const getIdList = [row.id];

      this.updateMetaTraderAccount(getIdList).then(resp => {
        if (resp.data) {
          this.hideRow(index, rows, row);
        }
      });
    },
    leverageClick(index, row) {
      this.$emit('leverageClick', index, row);
    },
    hideRow(index, rows, row) {
      this.$emit('changeTabele', row);
      rows.splice(index, 1);
    },
    updateMetaTraderAccount(hiddenMtAccountIdList = []) {
      return apiUpdateMetaTraderAccountHide({
        hiddenMtAccountIdList: hiddenMtAccountIdList,
        unHide: this.isHiddenTable,
        platform: this.mtServer
      });
    },
    clickResetButton(row) {
      this.$emit('resetNegativeBalance', row);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
